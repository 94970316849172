<template>
  <BaseDialog :model-value="true" size-class="!max-w-3xl h-[40rem]" @update:model-value="$emit('close')">
    <template #header> Add Members to {{ truncate(channel.data.name, 50) }} </template>
    <template #default>
      <div class="flex-col items-center space-y-2 px-2">
        <div class="ml-2 text-sm text-red-400">{{ memberError }}</div>
        <ChatUserSelect
          :users="filteredUsers"
          :team-member-count="filteredTeamMembers.length"
          :selected-team-tab="selectedTeamTab"
          :channel="props.channel"
          @user-selected="
            $event.selected = true;
            memberError = '';
          "
          @option-removed="$event.selected = false"
          @team-tab-selected="selectedTeamTab = $event"
        />
      </div>
      <div class="px-2 pt-2.5 text-xs font-normal text-gray-400" v-if="channel.data.message_history_access">
        Note: Newly added members will be able to see the full chat history.
      </div>
    </template>
    <template #footer>
      <div class="flex w-full items-center justify-end space-x-2 px-2">
        <BaseButton type="secondary" @press="$emit('close')">Cancel</BaseButton>
        <BaseButton :busy="buttonBusy" @press="addMemberstoChannel()">Add</BaseButton>
      </div>
    </template>
  </BaseDialog>
</template>
<script setup>
import { onMounted, ref, computed } from 'vue';
import ChatUserSelect from './ChatUserSelect.vue';
import { truncate } from '@/composeables/filters';
import { useMapGetter } from '@/store/map-state';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { saveNotificationPreference, getColor } from '@/composeables/chat';

const { client, streamUser, streamUsers } = useMapGetter();
const $emitter = useEmitter();

const props = defineProps({
  channel: Object
});

const $emit = defineEmits(['close']);
const $http = useHttp();

// USERS
const users = ref([]);
const selectedUsers = computed(() => {
  return users.value.filter(x => x.selected);
});
const filteredUsers = computed(() => {
  return users.value.filter(x => channelMembers.value.every(y => x.id !== y.user_id));
});
onMounted(() => {
  users.value = streamUsers.value.map(x => {
    return { ...x, selected: false };
  });
});

// MEMBERS
const buttonBusy = ref(false);
const memberError = ref('');
const channelMembers = ref(Object.values(props.channel.state.members));

const filteredTeamMembers = computed(() => {
  return users.value.filter(x => channelMembers.value.every(y => x.id !== y.user_id && x.team_id === y.team_id));
});

function setChannelMute(users, channelId) {
  $http.put('/mute_channel', {
    channel_id: channelId,
    user_ids: users
  });
}

function addMemberText(selectedTeamMembers, chat_type, status = 'added') {
  let firstThreeMembers = selectedTeamMembers.slice(0, 3);
  let text = `{{user:${streamUser.value.id}}} ${status} ${firstThreeMembers.map(u => `{{user:${u.id}}}`).join(', ')}`;
  if (firstThreeMembers.length === 3 && !(selectedTeamMembers.length - 3) <= 0) {
    text += ` and ${selectedTeamMembers.length - 3} other${selectedTeamMembers.length - 3 > 1 ? 's' : ''}`;
  }
  text += chat_type === 'channel' ? ' to the chat.' : ' to the group.';
  return text;
}

async function addMemberstoChannel() {
  if (selectedUsers.value.length == 0) {
    memberError.value = 'Please select atleast one person';
  } else {
    let selectedTeamMembers = selectedUsers.value.filter(user =>
      user.teams ? user.team_id == streamUser.value.team_id : false
    );
    let selectedExternalMembers =
      selectedUsers.value
        .filter(user => (user.teams ? user.team_id != streamUser.value.team_id : false))
        .map(user => user.id) || [];
    buttonBusy.value = true;
    $emit('close');
    if (props.channel.data.direct_messaging) {
      createGroupDM(selectedTeamMembers, selectedExternalMembers);
      return;
    }
    if (selectedExternalMembers.length) {
      let invitedUsers = props.channel.data.invited_users || [];
      invitedUsers.push({
        invited_users: selectedExternalMembers,
        invited_by: { id: streamUser.value.id, name: streamUser.value.name }
      });
      await props.channel.updatePartial({
        set: {
          invited_users: invitedUsers
        }
      });
      await props.channel.inviteMembers(selectedExternalMembers);
      const extMembersData = selectedUsers.value.filter(user =>
        user.teams ? user.team_id != streamUser.value.team_id : false
      );
      await props.channel.sendMessage({
        text: addMemberText(extMembersData, 'channel', 'has invited'),
        activity_status: 'invited',
        activity_members: messageMembers(extMembersData)
      });
      setChannelMute(selectedExternalMembers, props.channel.id);
    }
    if (selectedTeamMembers.length > 0) {
      await props.channel.addMembers(
        selectedTeamMembers.map(user => user.id),
        undefined,
        { hide_history: !props.channel.data.message_history_access }
      );
      if (selectedTeamMembers.length) {
        await props.channel.sendMessage({
          text: addMemberText(selectedTeamMembers, 'channel'),
          activity_status: 'added',
          activity_members: messageMembers(selectedTeamMembers)
        });
      }
      saveNotificationPreference(
        selectedTeamMembers.map(user => user.id),
        props.channel,
        'all'
      );
    }
  }
}

// CREATE GROUP DM
function messageMembers(teamMembers) {
  let data = [];
  data.push({ id: streamUser.value.id, name: streamUser.value.name });
  let firstThreeMembers = teamMembers.slice(0, 3);
  firstThreeMembers.forEach(m => {
    data.push({ id: m.id, name: m.name });
  });
  return data;
}

async function createGroupDM(teamUsers, externalUsers) {
  let channelMembers = selectedUsers.value;
  channelMembers.push(...Object.values(props.channel.state.members).map(x => x.user));

  let existingMembers = Object.values(props.channel.state.members);
  externalUsers.push(...existingMembers.filter(x => x.invited && !x.accepted_at).map(x => x.user_id));
  teamUsers.push(...existingMembers.filter(x => !externalUsers.includes(x.user_id)).map(x => x.user));

  const filter = {
    type: 'messaging',
    members: {
      $eq: channelMembers.map(x => x.id)
    },
    direct_group_messaging: true
  };
  const sort = [{ last_message_at: -1 }];
  let channels = await client.value.queryChannels(filter, sort, {});
  if (channels.length <= 0) {
    let id = Math.random().toString(16).slice(2);
    let channel_id = [
      selectedUsers.value
        .map(x => x.name)
        .join('_')
        .replace(/[^\w]/g, '')
        .substring(0, 64 - id.length - 1),
      id
    ].join('_');
    const channel = await client.value.channel('messaging', channel_id, {
      team: 'messaging',
      name: teamUsers.map(x => x.name).join(', '),
      members: teamUsers.map(x => x.id),
      background_color: getColor(),
      channel_state: 'private',
      channel_admins: [streamUser.value.id],
      direct_group_messaging: true,
      message_history_access: false,
      add_member_access: false,
      invited_users: externalUsers.length
        ? [{ invited_users: externalUsers, invited_by: { id: streamUser.value.id, name: streamUser.value.name } }]
        : []
    });
    await channel.watch();
    if (externalUsers.length) {
      await channel.inviteMembers(externalUsers);
      setChannelMute(externalUsers, channel.id);
    }
    await channel.sendMessage({
      text: `{{user:${streamUser.value.id}}} has created this group.`,
      activity_status: 'added',
      activity_members: [{ id: streamUser.value.id, name: streamUser.value.name }]
    });
    if (teamUsers.length) {
      await channel.sendMessage({
        text: addMemberText(
          teamUsers.filter(x => x.id !== client.value.user.id),
          'group'
        ),
        activity_status: 'added',
        activity_members: messageMembers(teamUsers.filter(x => x.id !== client.value.user.id))
      });
    }
    saveNotificationPreference(
      teamUsers.map(x => x.id),
      channel,
      'all'
    );
    $emitter.$emit('channel:added', channel);
  } else {
    $emitter.$emit('channel:change', channels[0]);
  }
}

// OTHERS
const selectedTeamTab = ref('team');
</script>
